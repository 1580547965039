:root {
  /* --track-gradient-vertical: linear-gradient(
    to bottom,
    rgba(175, 48, 41, 1),
    rgba(188, 82, 21, 1),
    rgba(173, 131, 1, 1),
    rgba(102, 128, 11, 1),
    rgba(36, 131, 123, 1),
    rgba(32, 94, 166, 1),
    rgba(94, 64, 157, 1),
    rgba(160, 47, 111, 1)
  ); */
  --track-gradient-vertical: linear-gradient(
    to bottom,
    #3D5B6A,
    #382444
  );
  --track-gradient-horizontal: linear-gradient(
    to right,
    #3D5B6A,
    #382444
  );
  --ui-button: #343331;
  --bg-color1: #173340;
  --bg-color2: #152035;
  --bg-color3: #1A0A24;
  --secondary-main: #E3D026;
  --secondary-light: #E9DB5D;
}

body {
  margin: 0;
  min-height: 100vh;
  height: max-content;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(var(--bg-color1), var(--bg-color2), var(--bg-color3));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Markdown Styling */
/* ---------------------------------------- */
.markdown-toc h1 {
  color: rgb(226, 232, 240);
  text-transform: uppercase;
  font-size: 1rem;
}

.markdown-toc a {
  color: rgb(148, 163, 184);
  text-decoration: none;
  font-weight: normal;
  font-size: 0.95rem;
  display: block;
}

.markdown-toc .active {
  color: var(--secondary-light);
  font-weight: bold;
}

/* Hide the first Introduction header */
.markdown-body h1:first-child {
  visibility: hidden;
  height: 0;
  margin: 0;
}

.markdown-body p:first-of-type {
  margin-top: 0;
}

/* Scrollbar Styling */
/* ---------------------------------------- */
::-webkit-scrollbar {
  width: 0.8rem;
  padding: 1rem;
  margin: 1rem;
  background: linear-gradient(var(--bg-color1), var(--bg-color2), var(--bg-color3));
}

::-webkit-scrollbar-track {
  background: var(--track-gradient-vertical);
  border-radius: 12px;
}

::-webkit-scrollbar-track:horizontal {
  background: var(--track-gradient-horizontal);
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 16px;
  background-color: rgba(255, 255, 255, 0.2);
	mix-blend-mode: overlay;
}

/* Fonts */
/* ---------------------------------------- */
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Black.woff2');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Bold.woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Light.woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Medium.woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Regular.woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-SemiBold.woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-Thin.woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-UltraBlack.woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Geist Sans';
  src: url('./fonts/Geist/Geist-UltraLight.woff2');
  font-weight: 200;
  font-style: normal;
}
